import React, { useState } from 'react'

export const OrderManager = ({
	isBlockedButtons,
	setIsBlockedButtons,
	payType,
	track,
	paid,
	ready,
	revoke,
	sale,
	changeStatus,
	deliveryPrice,
}) => {
	const [state, setState] = useState({
		trackNumber: track,
		delPrice: !deliveryPrice ? 0 : +deliveryPrice,
		dopSale: !sale ? 0 : +sale,
	})

	const onChangeField = event => {
		const name = event.currentTarget.name
		const value = event.currentTarget.value
		setState(prevState => ({ ...prevState, [name]: value }))
	}

	const onSend = (fieldName, fieldValue) => {
		setIsBlockedButtons(true)
		changeStatus({ fieldName, fieldValue })
	}

	const { trackNumber, delPrice, dopSale } = state

	return (
		<div className={'orders-item__manager'}>
			<h2 className={'orders-manager__title'}>
				Поменять статусы на: (нажмите на нужный)
			</h2>
			<div>
				<button
					className={'button'}
					onClick={onSend.bind(null, 'ready', !ready)}
					disabled={isBlockedButtons}
				>
					{ready ? 'В обработке' : 'Собран'}
				</button>
				{payType === 'bankcard' && (
					<button
						className={'button'}
						onClick={onSend.bind(null, 'paid', !paid)}
						disabled={isBlockedButtons}
					>
						{paid ? 'Не оплачен' : 'Оплачен'}
					</button>
				)}

				<button
					className={'button'}
					onClick={onSend.bind(null, 'revoke', !revoke)}
					disabled={isBlockedButtons}
				>
					{revoke ? 'Активный' : 'Отменён'}
				</button>
			</div>
			<h2 className={'orders-manager__title'}>Трек номер</h2>
			<div>
				<input
					type={'text'}
					name={'trackNumber'}
					value={trackNumber}
					onChange={onChangeField}
				/>
				<button
					className={'button'}
					onClick={onSend.bind(null, 'track', trackNumber)}
					disabled={isBlockedButtons || trackNumber === track}
				>
					Изменить
				</button>
			</div>
			<h2 className={'orders-manager__title'}>Стоимость доставки</h2>
			<div>
				<input
					type={'number'}
					name={'delPrice'}
					min={0}
					value={delPrice}
					onChange={onChangeField}
				/>
				<button
					className={'button'}
					onClick={onSend.bind(null, 'deliveryPrice', delPrice)}
					disabled={isBlockedButtons || +deliveryPrice === delPrice}
				>
					Изменить
				</button>
			</div>
			<h2 className={'orders-manager__title'}>Скидка клиенту</h2>
			<div>
				<input
					type={'number'}
					name={'dopSale'}
					min={0}
					value={dopSale}
					onChange={onChangeField}
				/>
				<button
					className={'button'}
					onClick={onSend.bind(null, 'sale', dopSale)}
					disabled={isBlockedButtons || +sale === dopSale}
				>
					Изменить
				</button>
			</div>
		</div>
	)
}
