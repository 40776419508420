import React, { useState, useCallback } from 'react'
import { reduxForm, Field } from 'redux-form'
import { FormItem } from '../../../components'
import { validateCollectionPrice as validate } from '../../../../../utils/validators'
import { ChoiceCollection } from './ChoiceCollection'
import { useSetToast } from '../../../../../hooks'

const EditCollectionPriceFormRoot = ({
	rawData,
	groups,
	setPrice,
	submitting,
	handleSubmit,
	change,
	untouch,
	valid,
	reset,
}) => {
	const [collectionData, setCollectionData] = useState(null)
	const [isIsset, setIsIsset] = useState(false)

	const { setToast } = useSetToast()

	const changeParentCollection = useCallback(
		data => {
			change('price', 0)
			untouch('price')
			setCollectionData(() => data)
		},
		[change, untouch]
	)

	const resetChange = useCallback(() => {
		reset()
		setCollectionData(() => null)
	}, [])

	const resetAll = useCallback(() => {
		resetChange()
		setIsIsset(true)
	}, [resetChange])

	const onSubmit = useCallback(
		values => {
			const { collectionId, price } = values
			setPrice({ collectionId, price }, setToast, resetAll)
		},
		[setPrice, resetAll]
	)

	return (
		<form className={'form'} onSubmit={handleSubmit(onSubmit)}>
			<ChoiceCollection
				groups={groups}
				rawData={rawData}
				getCollectionData={changeParentCollection}
				reset={resetChange}
				isIsset={isIsset}
				setIsIsset={setIsIsset}
			/>
			{collectionData && (
				<>
					<Field
						component={FormItem}
						type={'number'}
						name={'price'}
						label={'Укажите цену товаров'}
					/>
					<button
						type={'submit'}
						className={'button'}
						disabled={submitting || !valid}
					>
						Применить цену к товарам
					</button>
				</>
			)}
		</form>
	)
}

const EditCollectionSalePriceForm = reduxForm({
	form: 'editCollectionPrice',
	initialValues: {
		salePrice: 0,
	},
	validate,
})(EditCollectionPriceFormRoot)

export const EditCollectionPrice = ({ rawData, setPrice, groups }) => (
	<>
		<h2 className="form-title">Изменение цены для всех товаров в коллекции</h2>
		<EditCollectionSalePriceForm
			groups={groups}
			rawData={rawData}
			setPrice={setPrice}
		/>
	</>
)
