import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'

export const MyRoute = ({ component: Component, title, ...rest }) => {
	useEffect(() => {
		if (title) {
			document.title = title
		} else {
			document.title = 'Сибирский Лов'
		}
	}, [title])

	return <Route {...rest} render={props => <Component {...props} />} />
}

MyRoute.propTypes = {
	component: PropTypes.oneOfType([
		PropTypes.func.isRequired,
		PropTypes.object.isRequired,
	]),
}
