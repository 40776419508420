export const ADD_PRODUCT = 'ADD_PRODUCT'
export const ADD_ONE_PRODUCT = 'ADD_ONE_PRODUCT'
export const SUBTRACT_ONE_PRODUCT = 'SUBTRACT_ONE_PRODUCT'
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT'
export const REMOVE_ALL_PRODUCTS = 'REMOVE_ALL_PRODUCTS'
export const GET_PRODUCTS_DATA_REQUEST = 'GET_PRODUCTS_DATA_REQUEST'
export const GET_PRODUCTS_DATA_SUCCESS = 'GET_PRODUCTS_DATA_SUCCESS'
export const GET_PRODUCTS_DATA_FAILURE = 'GET_PRODUCTS_DATA_FAILURE'
export const RESET_PRODUCTS_DATA = 'RESET_PRODUCTS_DATA'
export const SET_PRODUCTS_FROM_ORDER = 'SET_PRODUCTS_FROM_ORDER'
export const SET_PRODUCTS_FROM_ADMIN = 'SET_PRODUCTS_FROM_ADMIN'
