import React from 'react'
import { formatDistanceToNowStrict } from 'date-fns'
import ru from 'date-fns/locale/ru'

import './About.scss'

export const About = () => {
	return (
		<section className={'about'}>
			<h1 className="page-title">Информация о нас</h1>
			<div className={'about-info'}>
				<p className={'about-info__item'}>
					Интернет-магазин <span className={'bold'}>"Сибирский Лов"</span>{' '}
					основа в 2017 году. Основатель магазина{' '}
					<span className={'bold'}>Павел Горохов</span> – рыболов-практик,
					вязальщик мушек со стажем более чем{' '}
					{formatDistanceToNowStrict(new Date('2006'), {
						unit: 'year',
						locale: ru,
					})}
					.
				</p>
				<p className={'about-info__item'}>
					Благодаря богатому опыту, мы точно знаем, какие товары будут полезны
					сибирскому рыболову. Не малую часть товаров мы производим на своем
					собственном производстве. Привозной ассортимент магазина не очень
					широк, но зато абсолютно все товары проверены и используются лично
					Павлом Гороховым.
				</p>
				<p className={'about-info__item'}>
					Мы ведем свой канал на видео портале{' '}
					<a target={'_blank'} href={'https://www.youtube.com/sibirskylov'}>
						YouTube
					</a>
					. Каждый клиент может лично пообщаться с основателем компании, задать
					любые вопросы и получить на них квалифицированный ответ. Благодаря
					прямой связи с потребителем, качество нашей продукции исключительно на
					высоком уровне! <span className={'bold'}>Наши сообщества</span> в
					социальных сетях –{' '}
					<span className={'bold'}>самые крупные в России</span>, по тематике
					Изготовления рыболовных приманок.
				</p>
				<p className={'about-info__item'}>
					<span className={'bold'}>Оптовикам:</span> для оптовых клиентов
					действуют особые предложения. Мы всегда открыты для диалога и готовы
					обсудить любые вопросы и предложения.
				</p>
			</div>
		</section>
	)
}
