import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { setStatusSuccessOrderApi } from '../../utils/api/orderApi'
import { Preloader, MessageWrapper } from '../../components'
import { checkResponse } from '../../utils/helpers/checkResponse'

export const CheckPaySuccess = () => {
	const { orderId } = useParams()
	const [loading, setLoading] = useState(true)
	const [isError, setIsError] = useState(false)

	useEffect(async () => {
		if (orderId) {
			setStatusSuccessOrderApi({ orderId })
				.then(res => {
					if (!checkResponse(res)) {
						setIsError(true)
					}
				})
				.catch(e => {
					console.log(e)
					setIsError(true)
				})
				.finally(() => {
					setLoading(false)
				})
		}
	}, [orderId])

	return (
		<section className={'paid'}>
			{loading ? (
				<Preloader title={'Ожидаем ответ от банка...'} />
			) : (
				<MessageWrapper>
					{isError ? (
						<h1>
							Ошибка! не удалось изменить стату заказа, обратитесь к менеджеру.
						</h1>
					) : (
						<h1 className={'paid-success'}>
							Спасибо! Ваш заказ успешно оплачен!
						</h1>
					)}
				</MessageWrapper>
			)}
		</section>
	)
}
