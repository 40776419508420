import React from 'react'
import { routes } from './routes'
import { NavLink, Route, Switch } from 'react-router-dom'

import './Help.scss'

export const Help = () => {
	const getNavigation = () =>
		routes && routes.length > 0
			? routes.map(item => (
					<NavLink className={'help__link'} key={item.link} to={item.link}>
						{item.title}
					</NavLink>
			  ))
			: null

	const routesSwitch = () => (
		<Switch>
			{routes && routes.length > 0
				? routes.map(route => {
						const Component = route.component
						return (
							<Route
								key={route.link}
								path={route.link}
								render={props => <Component {...props} />}
								exact
							/>
						)
				  })
				: null}
		</Switch>
	)

	return (
		<section className={'help-page'}>
			<div className={'help-nav'}>{getNavigation()}</div>
			{routesSwitch()}
		</section>
	)
}
