import React, { useMemo, useState, useCallback, useEffect, useRef } from 'react'
import Zoom from 'react-medium-image-zoom'
import { Properties, AddProductForm, ProductItem } from '../index'
import { getProperties } from '../../../../utils/helpers/getProperties'
import { CategoriesSlider } from '../../../../components'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-medium-image-zoom/dist/styles.css'

export const ViewProducts = ({
	link,
	isAuth,
	products,
	waitAction,
	collection,
	waitProducts,
	recommendation,
	addProductToCart,
	cancelWaitAction,
}) => {
	const [productId, setProductId] = useState(null)
	const [productData, setProductData] = useState(null)
	const [productNumber, setProductNumber] = useState(1)
	const [properties, setProperties] = useState(null)
	const oldId = useRef(null)
	const [nav1, setNav1] = useState(null)
	const [nav2, setNav2] = useState(null)
	const [slider1, setSlider1] = useState(null)
	const [slider2, setSlider2] = useState(null)

	useEffect(() => {
		setNav1(slider1)
		setNav2(slider2)
	}, [slider1, slider2])

	useEffect(() => {
		if (products && products.length > 0) {
			setProductData(() => products[0])
			setProductId(prev => {
				oldId.current = prev
				return products[0]._id
			})
		}
	}, [products])

	const newProperties = useMemo(() => {
		if (collection && productData) {
			const newProps = getProperties(
				collection.properties,
				productData.properties
			)
			return newProps
		}
		return null
	}, [productData, collection])

	useEffect(() => {
		setProperties(() => newProperties)
	}, [newProperties])

	const selectProduct = useCallback(
		event => {
			const id = event.currentTarget.dataset.id
			const number = event.currentTarget.dataset.number
			if (!!productId && productId !== id) {
				setProductId(prev => {
					oldId.current = prev
					return id
				})
				setProductData(() => products.find(item => item._id === id))
				setProductNumber(() => number)
			}
		},
		[products, productId]
	)

	const productsItems = useMemo(() => {
		if (products && products.length > 0) {
			const items = products.map((item, index) => (
				<ProductItem
					key={item._id}
					id={item._id}
					productId={productId}
					number={index + 1}
					title={item.title}
					image={item.image}
					selectProduct={selectProduct}
					mainParameter={item.mainParameter}
				/>
			))
			return items
		}
		return null
	}, [productId, products, selectProduct])

	const selectNewProduct = (product, col) => {
		setProductNumber(() => col)
		setProductId(prev => {
			oldId.current = prev
			return product._id
		})
		setProductData(() => product)
	}

	const sliderManager = useCallback(
		(direction = 'next') => {
			return () => {
				const colProducts = products.length
				const number = Number(productNumber)
				if (direction === 'prev') {
					if (number === 1) {
						const product = products[colProducts - 1]
						selectNewProduct(product, colProducts)
					} else {
						const product = products[number - 2]
						selectNewProduct(product, number - 1)
					}
				} else {
					if (number === colProducts) {
						const product = products[0]
						selectNewProduct(product, 1)
					} else {
						const product = products[number]
						selectNewProduct(product, number + 1)
					}
				}
			}
		},
		[products, productNumber]
	)
	const propertiesList = useMemo(() => {
		if (collection && productData) {
			return (
				<Properties
					properties={properties}
					title={productData.title}
					mainFieldTitle={collection.mainParameter}
					mainFieldValue={productData.mainParameter}
					container={collection.container}
					price={productData.price}
					salePrice={productData.salePrice}
				/>
			)
		}
		return null
	}, [properties, productData, collection])

	const getSlider = useCallback(
		(isThumbs = false) => {
			if (productData.slides.length > 0) {
				const settings = {
					autoplay: false,
					arrows: false,
					slidesToShow: 1,
					slidesToScroll: 1,
					asNavFor: `.slider-nav`,
				}

				const settingsThumbs = {
					slidesToShow: 5,
					slidesToScroll: 1,
					swipeToSlide: true,
					arrows: false,
					focusOnSelect: true,
					asNavFor: `.slider-for`,
				}
				const allSlides = [productData.image, ...productData.slides]
				if (!isThumbs) {
					const slides = allSlides.map(item => (
						<div key={item} className={'slick-product-slider__item'}>
							<Zoom>
								<img
									src={process.env.PUBLIC_URL + item}
									alt={productData.title}
								/>
							</Zoom>
						</div>
					))
					return (
						<Slider
							{...settings}
							className={'slick-product-slider'}
							asNavFor={nav2}
							ref={slider => setSlider1(slider)}
						>
							{slides}
						</Slider>
					)
				}

				const thumbs = allSlides.map((item, index) => (
					<div key={item + index} className={'slick-thumbs-nav__item'}>
						<img src={process.env.PUBLIC_URL + item} alt={productData.title} />
					</div>
				))

				return (
					<div className={'slick-thumbs'}>
						<Slider
							{...settingsThumbs}
							className={'slick-thumbs-nav'}
							asNavFor={nav1}
							ref={slider => setSlider2(slider)}
						>
							{thumbs}
						</Slider>
					</div>
				)
			}
			return null
		},
		[productData, nav1, nav2]
	)

	return (
		<>
			{collection && productData ? (
				<>
					<div className={'products-container'}>
						<div className={'products-section one'}>
							<div className={'products__wrap-img'}>
								{products && products.length > 1 && (
									<>
										<div
											className={'control-button control-button__left'}
											onClick={sliderManager('prev')}
										>
											<i className={'far fa-arrow-alt-circle-left'}></i>
										</div>
										<div
											className={'control-button control-button__right'}
											onClick={sliderManager('next')}
										>
											<i className={'far fa-arrow-alt-circle-right'}></i>
										</div>
									</>
								)}
								{productData && productData.slides.length > 0 ? (
									<>{getSlider()}</>
								) : (
									<Zoom className={'zoom'}>
										<img
											src={process.env.PUBLIC_URL + productData.image}
											alt={productData.title}
										/>
									</Zoom>
								)}
							</div>
							{productData.slides.length ? getSlider(true) : null}
							<AddProductForm
								link={link}
								isAuth={isAuth}
								waitProducts={waitProducts}
								waitAction={waitAction}
								cancelWaitAction={cancelWaitAction}
								title={productData.title}
								properties={properties}
								addProduct={addProductToCart}
								id={productData._id}
								oldId={oldId}
								price={
									!productData.salePrice
										? productData.price
										: productData.salePrice
								}
								isset={collection.isset ? productData.isset : false}
							/>
						</div>

						<div className="products-section two">
							<h3 className="products-title">Описание:</h3>
							<div className="products-description">
								{collection.content}{' '}
								{collection.link ? (
									<a
										className={'abort-link'}
										target={'_blank'}
										href={collection.link}
									>
										Смотрите видео с примером на нашем YouTube канале.
									</a>
								) : null}
							</div>

							{propertiesList}

							<h3 className="products-title">
								Выберите {collection.mainParameter.toLowerCase()}:
							</h3>
							<div className="products-items">
								<div className="products-items__container">{productsItems}</div>
							</div>
						</div>
					</div>
					{recommendation && recommendation.length > 0 ? (
						<div className="products-recomendation">
							<CategoriesSlider
								categories={recommendation}
								title={'Рекомнедованные товары'}
								page={'recommendation'}
							/>
						</div>
					) : null}
				</>
			) : null}
		</>
	)
}
