import React from 'react'
import './Politica.scss'

export const Politica = () => {
	return (
		<section className={'politica-page'}>
			<h1 className={'page-title'}>Политика обработки персональных данных</h1>
			<h2 className={'sub-title first'}>
				Сайт www.sibirskylov.ru, юридическое лицо
				<br />
				ИП Горохова Екатерина Олеговна ОГРНИП: 323420500000218
			</h2>
			<div className={'info'}>
				<p className={'info__item'}>
					Мы уважаем неприкосновенность Вашей частной жизни и заботимся о защите
					персональных данных. На этой странице мы ознакомим вас с политикой
					конфиденциальности.
				</p>
				<h3 className={'sub-title'}>
					Какую информацию мы собираем и с какой целью
				</h3>
				<p className={'info__item'}>
					Персональные данные - любая информация, относящаяся прямо или косвенно
					к определенному физическому лицу (субъекту персональных данных),
					такая, как Ваши имя и фамилия; паспортные данные, почтовый адрес или
					адрес электронной почты, номер телефона, а также пользовательские
					данные (сведения о местоположении; тип и версия ОС; тип и версия
					Браузера; тип устройства и разрешение его экрана; источник откуда
					пришел на сайт пользователь; с какого сайта или по какой рекламе; язык
					ОС и Браузера; какие страницы открывает и на какие кнопки нажимает
					пользователь; ip-адрес).
				</p>
				<p className={'info__item'}>
					Целью обработки персональных данных является совершение покупки на
					сайте <span className={'bold'}>www.sibirskylov.ru</span>, создание
					учетной записи, возможность задать вопрос специалистам компании, а
					также получение новостей о поступлениях, конкурсах и акциях.
				</p>
				<p className={'info__item'}>
					Для получения доступа к отдельным веб-страницам, требуется ввести имя
					пользователя (login) и пароль. Вы самостоятельно отвечаете за
					сохранение в тайне сведений о Вашем имени пользователя и пароле.
				</p>
				<p className={'info__item'}>
					Вы можете принять решение о предоставлении нам персональных данных в
					ряде ситуаций, в том числе в случаях, когда Вы:
				</p>
				<ul className={'politica-situation'}>
					<li className={'politica-situation__item'}>
						Регистрируетесь для открытия учетной записи на нашем сайте
					</li>
					<li className={'politica-situation__item'}>
						Запрашиваете определенные продукты или услуги
					</li>
					<li className={'politica-situation__item'}>
						Или предоставляете свои персональные данные по любой другой причине.
					</li>
				</ul>
				<h3 className={'sub-title'}>Ваш выбор и контроль</h3>
				<p className={'info__item'}>
					Мы всегда предупреждаем письменно перед тем, как начать сбор
					персональных данных, и извещаем Вас о целях сбора. Мы запросим Ваше
					согласие устно или письменно перед тем, как использовать Вашу
					информацию или передать ее третьим лицам в любых целях, отличных от
					причин, по которым Вы ее предоставили.
				</p>
				<h3 className={'sub-title'}>
					Как мы используем Ваши персональные данные
				</h3>
				<p className={'info__item'}>
					Поступающие от Вас <span className={'bold'}>персональные данные</span>{' '}
					подвергаются следующим видам обработки - сбор, систематизация,
					накопление, хранение, уточнение (обновление, изменение),
					использование, удаление, уничтожение.
				</p>
				<p className={'info__item'}>
					Мы будем обрабатывать Ваши персональные данные для предоставления
					запрошенных Вами продуктов, услуг, для подготовки ответов на Ваши
					запросы. Для этого нам может потребоваться предоставить Ваши
					персональные данные нашим партнерам. В таком случае мы предоставляем
					только информацию, необходимую для обработки Вашего запроса, и
					обязываем третьих лиц ограничить использование Ваших персональных
					данных исполнением Вашего запроса. С Вашего согласия, мы можем
					использовать Ваши персональные данные для того, чтобы связаться с Вами
					при проведении опросов относительно оценки удовлетворенности или для
					целей изучения рынка. Опустив или удалив информацию (такую, как Ваша
					фамилия), позволяющую идентифицировать информацию с Вами лично, мы
					также можем использовать Ваши обезличенные персональные данные для
					создания обезличенных массивов данных, проведения статистических
					исследований или для иных целей. Мы передадим Ваши персональные данные
					по официальному запросу уполномоченных лиц, если это требуется текущим
					законодательством, или в неотложных случаях для защиты жизни, здоровья
					или иных жизненно важных интересов субъекта персональных данных. Любые
					предоставленные Вами персональные данные будут использованы
					исключительно для осуществления заявленных целей или мероприятий, для
					которых они были представлены.
				</p>
				<h3 className={'sub-title'}>
					Срок действия согласия на обработку или дезактивацию учетной записи и
					возможность отзыва этого согласия
				</h3>
				<p className={'info__item'}>
					Регистрируясь на сайте, вы подписываете согласие на обработку
					персональных данных. Согласие обработку или дезактивацию учетной
					записи действует со дня его подписания в течение неопределенного
					срока.
				</p>
				<p className={'info__item'}>
					Если Вы решите отозвать согласие на обработку Ваших персональных
					данных или дезактивировать Вашу учетную запись на веб-сайте, то в
					случаях, когда закон это допускает, мы сохраним некоторую часть Ваших
					персональных данных для предотвращения мошенничества, разрешения
					споров, устранения неисправностей, соблюдения Ваших предпочтений
					относительно отказа от рассылки или согласия на обработку Ваших
					персональных данных, а также для соблюдения требований, установленных
					законодательством. Отозвать согласие на обработку Ваших персональных
					данных можно путем отправления запроса на электронный адрес{' '}
					<span className={'bold'}>sibirskylov@mail.ru</span>
				</p>
				<h3 className={'sub-title'}>Как мы защищаем Вашу информацию</h3>
				<p className={'info__item'}>
					Для обеспечения безопасности данных, которые Вы нам посылаете, мы
					используем технические и административные меры. Данные хранятся на
					оборудовании с контролируемым ограниченным доступом. Мы прилагаем все
					усилия для защиты Ваших персональных данных, но мы не можем обеспечить
					безопасность информации, которую Вы самостоятельно передаете через
					незащищенное соединение в сети Интернет. Поэтому мы призываем Вас
					принимать все предосторожности для защиты Ваших персональных данных во
					время Вашего пребывания в сети Интернет.
				</p>
				<h3 className={'sub-title'}>Изменения настоящей Политики</h3>
				<p className={'info__item'}>
					Настоящий документ был разработан в 2020г. Мы может периодически и в
					любое время вносить изменения в настоящую Политику. Каждый раз, когда
					в настоящую Политику будут вноситься существенные изменения, мы будем
					размещать уведомление на сайте. Дальнейшее использование Вами сайта
					<span className={'bold'}> www.sibirskylov.ru</span> после любого
					такого изменения будет означать и подтверждать, что Вы не возражаете
					против этого изменения.
				</p>
			</div>
		</section>
	)
}
