export const GET_ORDERS_FOR_ADMIN_REQUEST = 'GET_ORDERS_FOR_ADMIN_REQUEST'
export const GET_ORDER_BY_NUMBER_SUCCESS = 'GET_ORDER_BY_NUMBER_SUCCESS'
export const GET_ORDERS_FOR_ADMIN_FAILURE = 'GET_ORDERS_FOR_ADMIN_FAILURE'

export const GET_ORDERS_FOR_ADMIN_SUCCESS = 'GET_ORDERS_FOR_ADMIN_SUCCESS'

export const GET_ORDERS_BY_NOT_REG_USERS_SUCCESS =
	'GET_ORDERS_BY_NOT_REG_USERS_SUCCESS'

export const RESET_ORDERS = 'RESET_ORDERS'
