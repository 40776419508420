import React, { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'

export const API_ROOT = 'https://sibirskylov.ru'
const AUTHORIZATION_TOKEN =
	'Basic NDExOTg0OmxpdmVfcVFNN3k0bWs5aUVvMzd5WVZ0MVlmM0VpZmNQUzI0d3FwUjJ3U3gzZDQwbw=='
// const AUTHORIZATION_TOKEN =
// 	'Basic NDE5NDczOnRlc3Rfekt2SzVobWllXzZ0M1J2YmhKT2NYYjdXcHF5ZUxzUHBONmVqNWZZd2hIWQ=='

export const PayForm = ({ orderNumber, amount, orderId }) => {
	const [checkout, setCheckout] = useState(null)

	useEffect(() => {
		async function connectToYookassa() {
			try {
				const response = await fetch('https://api.yookassa.ru/v3/payments', {
					method: 'POST',
					body: {
						amount: {
							value: `${amount}`,
							currency: 'RUB',
						},
						payment_method_data: {
							type: 'bank_card',
						},
						confirmation: {
							type: 'embedded',
						},
						capture: true,
						description: `Заказ № ${orderNumber}`,
					},
					headers: {
						Authorization: AUTHORIZATION_TOKEN,
						'Idempotence-Key': uuidv4().toString(),
						'Content-Type': 'application/json',
					},
				})

				const data = response.json()

				const checkout = new window.YooMoneyCheckoutWidget({
					confirmation_token: data.confirmation.confirmation_token,
					return_url: `${API_ROOT}/check-pay/${orderId}`,
				})

				if (checkout) setCheckout(checkout)
			} catch (e) {
				console.log(e)
			}
		}

		connectToYookassa()

		return () => {
			if (checkout) checkout.destroy()
		}
	}, [orderId, amount, orderNumber])

	if (!checkout) return null

	return (
		<>
			<span
				onClick={() => checkout?.render('payment-form')}
				className={'button'}
				style={{ display: 'inline-block' }}
			>
				Оплатить любой картой (нажать)
			</span>
			<div id="payment-form"></div>
		</>
	)
}

// import React, { useCallback } from 'react'
// import { useLayoutEffect } from 'react'
// import { setStatusSuccessOrderApi } from '../../../utils/api/orderApi'

// export const PayForm = ({
// 	orderNumber,
// 	amount,
// 	orderId,
// 	setSuccessStatus = () => {},
// }) => {
// 	const showSuccessfulPurchase = useCallback(
// 		data => {
// 			setStatusSuccessOrderApi({ orderId: data.orderNumber })
// 			setSuccessStatus()
// 		},
// 		[setStatusSuccessOrderApi, setSuccessStatus]
// 	)

// 	const showFailurefulPurchase = () => {
// 		console.log('fail')
// 	}

// 	useLayoutEffect(() => {
// 		new window.SbolPay({
// 			selector: '#pay-button',
// 			returnUrl: `https://sibirskylov.ru/check-pay/${orderId}`,
// 			token: '36b35ab1jq1dvk2pbqrrf83i3j',
// 			amount: amount * 100,
// 			shopName: 'Сибирский лов',
// 			orderNumber,
// 		})
// 	}, [])

// 	return (
// 		<>
// 			<a
// 				onClick={() =>
// 					window.ipayCheckout(
// 						{
// 							amount: amount,
// 							currency: 'RUB',
// 							order_number: `${orderId}`,
// 							description: `Заказ № ${orderNumber}`,
// 						},
// 						function(data) {
// 							showSuccessfulPurchase(data)
// 						},
// 						function() {
// 							showFailurefulPurchase()
// 						}
// 					)
// 				}
// 				className={'button'}
// 				style={{ display: 'inline-block' }}
// 			>
// 				Оплатить любой картой (нажать)
// 			</a>
// 			<div id="pay-button"></div>
// 		</>
// 	)
// }

//
// import { DATA_FOR_SIGNATURE } from '../../../utils/constants'
// import { getOrderData, getSignature } from '../../../utils/helpers/getSIgnature'
// export const PayForm = ({ orderId, email, phone, amount, orderNumber }) => {
// 	const time = Math.round(Date.now() / 1000)
// 	const signature = getSignature({
// 		orderId,
// 		amount,
// 		orderNumber,
// 		time,
// 		email,
// 		phone,
// 	})
// 	const payData = getOrderData(amount)
// 	const {
// 		merchantId,
// 		successUrl,
// 		testing,
// 		salt,
// 		// receiptContact,
// 		callbackUrl,
// 	} = DATA_FOR_SIGNATURE
// 	return (
// 		<form method={'post'} action={'https://pay.modulbank.ru/pay'}>
// 			<input type={'hidden'} name={'testing'} value={`${testing}`} />
// 			<input type={'hidden'} name={'salt'} value={salt} />
// 			<input type={'hidden'} name={'order_id'} value={orderId} />
// 			<input type={'hidden'} name={'custom_order_id'} value={orderNumber} />
// 			<input type={'hidden'} name={'amount'} value={`${amount}`} />
// 			<input type={'hidden'} name={'merchant'} value={merchantId} />
// 			<input type={'hidden'} name={'signature'} value={signature} />
// 			<input
// 				type={'hidden'}
// 				name={'description'}
// 				value={`Заказ № ${orderNumber}`}
// 			/>
// 			<input type={'hidden'} name={'callback_url'} value={callbackUrl} />
// 			<input type={'hidden'} name={'client_phone'} value={phone} />
// 			<input type={'hidden'} name={'client_email'} value={email} />
// 			<input type={'hidden'} name={'success_url'} value={successUrl} />
// 			<input type={'hidden'} name={'receipt_contact'} value={email} />
// 			<input type={'hidden'} name={'unix_timestamp'} value={time} />
// 			<input type={'hidden'} name={'receipt_items'} value={payData} />
// 			<input type={'submit'} className={'button'} value={'Оплатить'} />
// 		</form>
// 	)
// }
