import React, { useEffect } from 'react'
import { Route, useLocation } from 'react-router-dom'
import { Switch, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { isAuthSelector, userSelector } from './redux/selectors'

import { NotFound } from '../NotFound'
import { ForgotForm, LoginForm, SignupForm } from './components'

import './Auth.scss'

const Auth = ({ isAuth, user: { role }, history }) => {
	const location = useLocation()
	useEffect(() => {
		if (isAuth) {
			if (
				location &&
				location.state &&
				location.state.prevLocation &&
				location.state.prevLocation !== '/'
			) {
				history.push(location.state.prevLocation)
			} else {
				role === 'admin' ? history.push('/admin') : history.push('/user')
			}
		}
	}, [isAuth, role, history, location])

	return (
		<section className="auth">
			<div className="auth-page">
				<Switch>
					<Route exact path={'/login'} component={LoginForm} />
					<Route exact path={'/registration'} component={SignupForm} />
					<Route exact path={'/forgot-password'} component={ForgotForm} />
					<Route component={NotFound} />
				</Switch>
			</div>
		</section>
	)
}

export default withRouter(
	connect(
		state => ({
			isAuth: isAuthSelector(state),
			user: userSelector(state),
		}),
		{}
	)(Auth)
)
