import React from 'react'
import { UpdateCollection } from './UpdateCollection'
import { ChangeRecommendations } from './ChangeRecommendations'
import { EditCollectionSalePrice } from './EditCollectionSalePrice'
import { EditCollectionPrice } from './EditCollectionPrice'

// import { useSetToast } from '../../../../../hooks'

export const UpdateCollectionPage = ({
	groups,
	rawData,
	changeCollection,
	deleteRecommendations,
	addRecommendations,
	setSalePrice,
	setPrice,
}) => {
	return (
		<div>
			<UpdateCollection
				groups={groups}
				rawData={rawData}
				changeCollection={changeCollection}
			/>
			<ChangeRecommendations
				groups={groups}
				rawData={rawData}
				deleteRecommendations={deleteRecommendations}
				addRecommendations={addRecommendations}
			/>
			<EditCollectionSalePrice
				groups={groups}
				rawData={rawData}
				setSalePrice={setSalePrice}
			/>
			<EditCollectionPrice
				groups={groups}
				rawData={rawData}
				setPrice={setPrice}
			/>
		</div>
	)
}
