import React, { useState, useEffect } from 'react'
import { reduxForm, Field } from 'redux-form'
import { FormItem, SelectSectGrCol, OptionsList } from '../../../components'
import { useSetToast } from '../../../../../hooks'

export const UpdateProductSlideForm = ({
	rawData,
	getProducts,
	productsState: { products, isLoading, errorMsg },
	resetProducts,
	setSlidesToProductAction,
	deletSlideOfProductAction,
	handleSubmit,
	submitting,
	initialize,
	reset,
	change,
}) => {
	const [choiceCollection, setChoiceCollection] = useState(null)
	const [choiceProduct, setChoiceProduct] = useState(null)
	const [isReset, setIsReset] = useState(false)

	const { setToast } = useSetToast()

	useEffect(() => {
		if (choiceCollection && choiceCollection.products.length > 0) {
			setChoiceProduct(null)
			getProducts(choiceCollection._id)
		}
		return () => resetProducts()
	}, [choiceCollection, getProducts, resetProducts])

	useEffect(() => {
		if (choiceProduct) {
			const { _id } = choiceProduct
			initialize(
				{
					_id,
				},
				true
			)
		}
	}, [choiceProduct, initialize])

	const getCollectionData = data => {
		resetProducts()
		setChoiceProduct(null)
		setChoiceCollection(data)
		change('productId', null)
	}

	const onSelectProduct = event => {
		const id = event.currentTarget.value
		const product = products.find(item => item._id === id)
		setChoiceProduct(product)
	}

	const resetAll = () => {
		resetProducts()
		setChoiceCollection(null)
		setChoiceProduct(null)
		setIsReset(true)
		change('images', null)
		change('productId', null)
	}

	const removeSlideFromProduct = imagePath => {
		const newSlidesArr = choiceProduct.slides.filter(path => path !== imagePath)
		setChoiceProduct(prev => ({ ...prev, slides: newSlidesArr }))
	}

	const deleteSlide = imagePath => {
		deletSlideOfProductAction(
			{ imagePath },
			setToast,
			removeSlideFromProduct.bind(null, imagePath)
		)
	}

	const getSlides = slides =>
		slides.map(imagePath => (
			<div key={imagePath} className={'product-slides__item'}>
				<img src={process.env.PUBLIC_URL + imagePath} alt={'slide'} />
				<span
					className={'product-slides__item-button'}
					onClick={deleteSlide.bind(null, imagePath)}
				>
					<i className={'far fa-times-circle'} />
				</span>
			</div>
		))

	const onSubmit = values => {
		const { _id, images } = values

		let isChangeFields = false

		if (images && images.length > 0) {
			isChangeFields = true
		}

		if (!isChangeFields) {
			setToast({
				data: {
					type: 'error',
					title: 'Ошибка!',
					message: 'Выбирите картинки',
				},
			})
			return false
		}
		setSlidesToProductAction({ _id, ...images }, setToast, resetAll)
	}

	return (
		<>
			<form className={'form'} onSubmit={handleSubmit(onSubmit)}>
				<h2 className="form-title">Изменить слайды</h2>
				<SelectSectGrCol
					rawData={rawData}
					reset={reset}
					setIsReset={setIsReset}
					change={change}
					getCollection={getCollectionData}
					resetAll={isReset}
				/>
				{products && (
					<Field
						fieldName={'select'}
						component={FormItem}
						name={'productId'}
						label={'Выберите продукт для изменения'}
						onChange={onSelectProduct}
					>
						<option></option>
						<OptionsList groups={products} />
					</Field>
				)}

				{isLoading && <div className={'loading'}>Загрузка товаров...</div>}
				{errorMsg && <div className={'loading'}>{errorMsg}</div>}
				{choiceCollection && choiceCollection.products.length === 0 && (
					<div className={'loading'}>Товаров в этой коллекции пока нет</div>
				)}
				{choiceProduct && (
					<>
						<Field
							component={FormItem}
							type={'file'}
							multiple={true}
							name={'images'}
							label={'Загрузить картинки'}
						/>
						<Field component={'input'} type={'hidden'} name={'_id'} />

						<button type={'submit'} className={'button'} disabled={submitting}>
							добавить слайды
						</button>
					</>
				)}
			</form>
			{choiceProduct &&
			choiceProduct.slides &&
			choiceProduct.slides.length > 0 ? (
				<div className={'product-slides'}>
					{getSlides(choiceProduct.slides)}
				</div>
			) : null}
		</>
	)
}

export const UpdateProductSlide = reduxForm({
	form: 'changeProductSlide',
})(UpdateProductSlideForm)
