import React from 'react'
import { UpdateProduct } from './UpdateProduct'
import { UpdateProductSlide } from './UpdateProductSlide'

export const UpdateProductContainer = ({
	rawData,
	groups,
	changeProduct,
	getProducts,
	productsState,
	resetProducts,
	setSlidesToProductAction,
	deletSlideOfProductAction,
}) => {
	return (
		<>
			<UpdateProduct
				groups={groups}
				rawData={rawData}
				getProducts={getProducts}
				productsState={productsState}
				resetProducts={resetProducts}
				changeProduct={changeProduct}
			/>
			<UpdateProductSlide
				rawData={rawData}
				getProducts={getProducts}
				productsState={productsState}
				resetProducts={resetProducts}
				setSlidesToProductAction={setSlidesToProductAction}
				deletSlideOfProductAction={deletSlideOfProductAction}
			/>
		</>
	)
}
