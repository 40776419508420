import React from 'react'
import './Contact.scss'

export const Contact = () => {
	return (
		<section className={'page-contact'}>
			<h1 className={'page-title'}>Наша контакты</h1>

			<div className={'page-contact__item'}>
				<h3 className={'sub-title'}>Как нас найти</h3>
				<div className={'page-contact__info'}>
					650000, Кемерово, ул. Красноармейская, 138
				</div>
				<div className={'page-contact__info'}>
					<iframe
						className={'page-contact__map'}
						src={
							'https://yandex.ru/map-widget/v1/?ll=86.086812%2C55.348314&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1NjMwODgzNBJO0KDQvtGB0YHQuNGPLCDQmtC10LzQtdGA0L7QstC-LCDQmtGA0LDRgdC90L7QsNGA0LzQtdC50YHQutCw0Y8g0YPQu9C40YbQsCwgMTM4IgoNciysQhWtZF1C&z=16.51'
						}
						title={'Расположение нашего магазина'}
						frameborder={'0'}
						allowfullscreen={''}
					></iframe>
				</div>
			</div>
			<div className={'flex-container'}>
				<div className={'page-contact__item'}>
					<h3 className={'sub-title'}>Режим работы</h3>
					<div className={'page-contact__info'}>Пн-Пт: 09:00-17:00</div>
					<div className={'page-contact__info'}>Сб-Вс: Выходной</div>
				</div>
				<div className={'page-contact__item'}>
					<h3 className={'sub-title'}>Наши E-mail</h3>
					<div className={'page-contact__info'}>
						<a
							href={'mailto:sibirskylov@bk.ru'}
							target={'_blank'}
							rel={'noopener noreferrer'}
						>
							sibirskylov@bk.ru
						</a>
					</div>
					<div className={'page-contact__info'}>
						<a
							href={'mailto:sibirskylov@mail.ru'}
							target={'_blank'}
							rel={'noopener noreferrer'}
						>
							sibirskylov@mail.ru
						</a>
					</div>
				</div>
				<div className={'page-contact__item'}>
					<h3 className={'sub-title'}>Tel/Viber/WhatsApp</h3>
					<div className={'page-contact__info'}>
						<a href={'tel:89236216738'}>8-923-621-67-38</a>
					</div>
					<div className={'page-contact__info'}>
						<a href={'tel:89089524291'}>8-908-952-42-91</a>
					</div>
				</div>
			</div>
		</section>
	)
}
