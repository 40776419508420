import React, { useEffect, useRef, useCallback } from 'react'
import { connect } from 'react-redux'
import { useSetToast } from '../../hooks'
import { getCartSelector } from './redux/selectors'
import { userDataSelector } from '../User/redux/selectors'
import { isAuthSelector, userSelector } from '../Auth/redux/selectors'
import { getUserData } from '../User/redux/actions'
import {
	changeProductsByOrderAction,
	subtractOneProductFromCart,
	removeAllProductsFromCart,
	removeOneProductFromCart,
	addOneProductToCart,
	getProductsForCart,
	resetProductsData,
	sendAnOrder,
} from './redux/actions'

import { CartItem, ModalChoice, Order } from './components'
import { Preloader, Empty, Error, Modal } from '../../components'
import './Cart.scss'
import { useState } from 'react'

const Cart = ({
	cartData: {
		products,
		totalCount,
		totalPrice,
		productsIds,
		loadedData,
		isLoading,
		errorMsg,
		orderId,
	},
	isAuth,
	userData: { user, address },
	user: { role, isCheck },
	changeProductsByOrderAction,
	subtractOneProductFromCart,
	removeAllProductsFromCart,
	removeOneProductFromCart,
	addOneProductToCart,
	getProductsForCart,
	resetProductsData,
	getUserData,
	sendAnOrder,
}) => {
	const didMount = useRef(false)
	const [isOpenOrderForm, setIsOpenOrderForm] = useState(false)
	const { setToast } = useSetToast()

	useEffect(() => {
		if (didMount) {
			didMount.current = false
			resetProductsData()
		}
	}, [didMount, resetProductsData])

	useEffect(() => {
		if (productsIds.length > 0 && !didMount.current) {
			getProductsForCart({ productsIds: [...productsIds] })
			didMount.current = true
		}
	}, [productsIds, getProductsForCart])

	useEffect(() => {
		if (isAuth && !user && !address) {
			getUserData()
		}
	}, [user, address, getUserData, isAuth])

	const getOrderProducts = useCallback(() => {
		const orderProducts = productsIds.map(id => {
			return (
				<CartItem
					key={id}
					id={id}
					{...products[id]}
					add={addOneProductToCart}
					subtract={subtractOneProductFromCart}
					remove={removeOneProductFromCart}
				/>
			)
		})
		return orderProducts
	}, [
		productsIds,
		products,
		addOneProductToCart,
		subtractOneProductFromCart,
		removeOneProductFromCart,
	])

	const sendChangedOrder = () => {
		changeProductsByOrderAction({ products, orderId }, setToast)
	}

	return (
		<div className={'page-cart'}>
			<h1 className={'page-title'}>Ваша корзина</h1>
			{isLoading ? (
				<Preloader title={'Загружаются данные...'} />
			) : errorMsg ? (
				<Error title={errorMsg} />
			) : productsIds.length > 0 && loadedData ? (
				<>
					<table className={'main-cart'}>
						<thead className={'main-cart__header'}>
							<tr>
								<th>Вид</th>
								<th className={'desc'}>Товар</th>
								<th>Цена р.</th>
								<th>Кол.</th>
								<th>Сумма р.</th>
								<th>
									<i className={'fas fa-trash-alt'}></i>
								</th>
							</tr>
						</thead>
						<tbody>{getOrderProducts()}</tbody>
						<tfoot className={'main-cart__footer'}>
							<tr>
								<td></td>
								<td></td>
								<td className={'main-cart__footer-title'}>Итого:</td>
								<td>{totalCount}</td>
								<td>{totalPrice}</td>
								<td></td>
							</tr>
						</tfoot>
					</table>
					<div className="wrap-button">
						<Modal textButton={'Заказать'}>
							{(isAuth && isCheck) || isOpenOrderForm ? (
								<Order
									user={user}
									address={address}
									products={products}
									sendAnOrder={sendAnOrder}
								/>
							) : (
								<ModalChoice setIsOpenOrderForm={setIsOpenOrderForm} />
							)}
						</Modal>
						<button className={'button'} onClick={removeAllProductsFromCart}>
							Очистить
						</button>
						{role && role === 'admin' && orderId ? (
							<button className={'button'} onClick={sendChangedOrder}>
								Изменить заказ
							</button>
						) : null}
					</div>
				</>
			) : (
				<Empty title={'Вы пока не выбрали ни одного товара'} />
			)}
		</div>
	)
}

export default connect(
	state => ({
		cartData: getCartSelector(state),
		userData: userDataSelector(state),
		isAuth: isAuthSelector(state),
		user: userSelector(state),
	}),
	{
		changeProductsByOrderAction,
		subtractOneProductFromCart,
		removeAllProductsFromCart,
		removeOneProductFromCart,
		addOneProductToCart,
		getProductsForCart,
		resetProductsData,
		getUserData,
		sendAnOrder,
	}
)(Cart)
