export const REGISTRATION_POST_REQUEST = 'REGISTRATION_POST_REQUEST'
export const REGISTRATION_POST_SUCCESS = 'REGISTRATION_POST_SUCCESS'
export const REGISTRATION_POST_FAILURE = 'REGISTRATION_POST_FAILURE'

export const LOGIN_POST_REQUEST = 'LOGIN_POST_REQUEST'
export const LOGIN_POST_SUCCESS = 'LOGIN_POST_SUCCESS'
export const LOGIN_POST_FAILURE = 'LOGIN_POST_FAILURE'

export const CHANGE_WAIT_PRODUCTS = 'CHANGE_WAIT_PRODUCTS'

export const EMAIL_IS_CHECKED = 'EMAIL_IS_CHECKED'

export const LOGOUT = 'LOGOUT'
