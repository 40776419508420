import React from 'react'
import './Order.scss'

export const Order = () => {
	return (
		<section className={'how-ordered'}>
			<h1 className={'page-title'}>Как сделать заказ на нашем сайте</h1>
			<ul className={'order-stap'}>
				<li className={'order-stap__item'}>
					Выберете товар и добавьте его в корзину
				</li>
				<li className={'order-stap__item'}>
					В правом верхнем углу нажмите на значок корзины
				</li>
				<li className={'order-stap__item'}>Нажмите кнопку «Заказать»</li>
				<li className={'order-stap__item'}>
					Пройдите регистрацию или войдите в личный кабинет
				</li>
				<li className={'order-stap__item'}>
					Если Вы авторизовались и заполнили все поля в Вашем кабинете, то Ваши
					данные и адрес доставки будут заполнены автоматически
				</li>
				<li className={'order-stap__item'}>
					Если Вы не авторизовались, то введите Ваши данные и укажите адрес
					доставки
				</li>
				<li className={'order-stap__item'}>
					Выберите способ доставки, способ связи, способ оплаты, если есть
					какие-то пожелания или уточнения к заказу, то оставьте комментарий
				</li>
				<li className={'order-stap__item'}>
					Нажмите кнопку «Заказать» в конце формы
				</li>
			</ul>
			<div className={'info'}>
				<p className={'info__item'}>
					После оформления заказа Вы получите подтверждающее письмо на Вашу
					электронную почту. Когда Ваш заказ будет полностью собран, его статус
					изменится на <span className={'bold'}>«готов к оплате»</span> и вы
					получите соответствующие уведомление на вашу электронную почту.
					Пройдите в личный кабинет и оплатите свой заказ. После оплаты, Ваш
					заказ будет отправлен в течении трех рабочих дней и вы получите
					соответствующее уведомление на Вашу электронную почту. В личном
					кабинете появится трек номер – для отслеживания Вашей посылки.
				</p>
			</div>

			<h2 className={'page-title page-title__second'}>Если забыли пароль</h2>
			<ul className={'order-stap'}>
				<li className={'order-stap__item'}>
					На странице входа перейдите по ссылке "Забыли пароль?"
				</li>
				<li className={'order-stap__item'}>
					Введите Ваш E-mail и новый пароль
				</li>
				<li className={'order-stap__item'}>Нажмите кнопку "Отправить"</li>
				<li className={'order-stap__item'}>
					Вам на E-mail прийдёт новое письмо с Вашим новым паролем и ссылка на
					повторную активацию личного кабинета
				</li>
				<li className={'order-stap__item'}>
					Перейдите по ссылке и Ваш кабинет будет снова активен
				</li>
				<li className={'order-stap__item'}>
					Для входа пользуйтесь новым паролем!
				</li>
			</ul>
		</section>
	)
}
