import React from 'react'
import { NavLink } from 'react-router-dom'

export const ModalChoice = ({ setIsOpenOrderForm }) => {
	return (
		<div className={'modal-choice'}>
			<h2 className={'form-title'}>Авторизуйтесь</h2>
			<p>
				Для оформления заказа авторизуйтесь или зарегистрируйтесь. Если Ваш
				кабинет не активирован, то активируйте его. При возникновении любых
				вопросов свяжитесь с нами удобным для Вас способом, наши контакты
				указаны в низу сайта.{' '}
				<span className={'bold'}>
					При авторизации Вы получите следующие возможности:{' '}
				</span>
				упрощенно производить заказ, следить за их историей и изменением
				статуса, отслеживать движение посылок, повторять заказы.
			</p>
			<div className={'modal-choice__wrap_button'}>
				<NavLink to={'/login'} className={'button'}>
					Авторизоваться
				</NavLink>
				{/* <span
					className={'modal-choice__button'}
					onClick={() => setIsOpenOrderForm(true)}
				>
					Продолжить
				</span> */}
			</div>
		</div>
	)
}
