import React from 'react'
import './NoAccess.scss'

export const NoAccess = () => {
	return (
		<div className={'no_access'}>
			<h1 className={'no_access-title'}>
				У Вас нет доступа к личному кабинету!
			</h1>
			<p className={'no_access-info'}>
				Для получения доступа подтвердите E-mail, перейдите по ссылке, которая
				Вам отправлена на E-mail при регистрации.
			</p>
		</div>
	)
}
