import React from 'react'
import { connect } from 'react-redux'
import { useSetToast } from '../../../../hooks'

import { isLoadingSelector } from '../../redux/selectors'
import { forgotAction } from '../../redux/actions'

import { validateLoginForm as validate } from '../../../../utils/validators'

import { Link } from 'react-router-dom'
import { reduxForm, Field } from 'redux-form'
import { FormItem } from '../FormItem'

import '../LoginForm/LoginForm.scss'

const ForgotReduxForm = ({
	isLoading,
	forgotAction,
	handleSubmit,
	submitting,
	valid,
}) => {
	const { setToast } = useSetToast()

	const submitForm = body => {
		forgotAction(body, setToast)
	}

	return (
		<div>
			<div className={'auth-page'}>
				<form
					onSubmit={handleSubmit(submitForm)}
					action={'post'}
					className={'auth-form'}
				>
					<h1 className={'auth-form__title'}>Сменить пароль</h1>
					<Field
						component={FormItem}
						type={'email'}
						name={'email'}
						label={'Адрес электронной почты'}
						placeholder={'Введите email'}
					/>
					<Field
						component={FormItem}
						name={'password'}
						label={'Новый пароль'}
						placeholder={'Введите новый пароль'}
					></Field>

					<div className={'auth-form__wrapper wrap-button'}>
						<button
							type={'submit'}
							className={'button auth-form__button'}
							disabled={submitting || !valid || isLoading}
						>
							Отправить
						</button>
					</div>

					<div className={'auth-form__wrapper wrap-redirect'}>
						<Link to={'/login'} className={'auth-form__redirect-link'}>
							Вернуться
						</Link>
					</div>
				</form>
			</div>
		</div>
	)
}

const ForgotForm = reduxForm({ form: 'forgot', validate })(ForgotReduxForm)

export default connect(
	state => ({
		isLoading: isLoadingSelector(state),
	}),
	{ forgotAction }
)(ForgotForm)
