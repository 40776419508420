import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSetToast } from '../../../../hooks'

export const AddProductForm = ({
	id,
	link,
	isset,
	title,
	price,
	isAuth,
	productId,
	properties,
	addProduct,
	waitProducts,
	waitAction,
	cancelWaitAction,
}) => {
	const [productCount, setProductCount] = useState(1)
	const [isWaiting, setIsWaiting] = useState(false)
	const location = useLocation()
	const { setToast } = useSetToast()

	useEffect(() => {
		if (waitProducts && waitProducts.includes(id)) {
			return setIsWaiting(true)
		}
		return setIsWaiting(false)
	}, [waitProducts, id])

	useEffect(() => {
		if (productId !== id) {
			setProductCount(1)
		}
	}, [productId, id])

	const addProductToCart = e => {
		e.preventDefault()
		addProduct(
			{ _id: id, price, title, count: Number(productCount), properties, link },
			setToast
		)
		setProductCount(1)
	}

	return (
		<form className={'products-actions'} onSubmit={addProductToCart}>
			<h3 className="products-title">{title}</h3>
			{isset ? (
				<>
					<div className="products-actions__container">
						<input
							type={'number'}
							className={'products-actions__count'}
							min={'1'}
							onChange={e => setProductCount(e.currentTarget.value)}
							value={productCount}
						/>
						<button type={'submit'} className={'products-actions__add'}>
							Добавить в корзину
						</button>
					</div>
					<span className="products-actions__summ">
						Сумма: {productCount * price} руб
					</span>
				</>
			) : (
				<>
					<div className={'product-empty'}>Товар временно отсутствет</div>
					<div className="products-actions__container">
						{isAuth ? (
							isWaiting ? (
								<button
									className={'products-actions__add'}
									onClick={e => {
										e.preventDefault()
										cancelWaitAction(id, setToast)
									}}
								>
									Не оповещать о поступлении
								</button>
							) : (
								<button
									className={'products-actions__add'}
									onClick={e => {
										e.preventDefault()
										waitAction(id, setToast)
									}}
								>
									Оповестить о поступлении
								</button>
							)
						) : (
							<Link
								to={{
									pathname: '/login',
									state: { prevLocation: location.pathname },
								}}
							>
								<button className={'products-actions__add'}>
									Войти для отслеживания
								</button>
							</Link>
						)}
					</div>
				</>
			)}
		</form>
	)
}
