import React from 'react'

import './Manual.scss'

export const Manual = () => {
	return (
		<div className={'Manual-page'}>
			<h1 className={'page-title'}>Инструкции к панели администратора</h1>
		</div>
	)
}
