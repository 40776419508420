import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { isAuthSelector, userSelector } from '../Auth/redux/selectors'
import { NoAccess, CheckEmail } from './components'
import { checkEmailAction } from './components/CheckEmail/redux/actions'
import './CheckPage.scss'

const CheckPage = ({
	isAuth,
	user: { isCheck },
	history,
	checkEmailAction,
}) => {
	useEffect(() => {
		if (!isAuth || (isAuth && isCheck)) {
			history.push('/')
		}
	})

	return (
		<section className={'check'}>
			<Route exact path={'/check'} component={NoAccess} />
			<Route
				exact
				path={'/check/:email'}
				render={() => (
					<CheckEmail checkEmailAction={checkEmailAction} history={history} />
				)}
			/>
		</section>
	)
}

export default withRouter(
	connect(
		state => ({
			isAuth: isAuthSelector(state),
			user: userSelector(state),
		}),
		{ checkEmailAction }
	)(CheckPage)
)
