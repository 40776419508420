import React, { useCallback, useEffect, useRef, useMemo } from 'react'
import { useParams } from 'react-router'
import { format } from 'date-fns'
import { Empty, Error, Preloader, BreadCrumbs } from '../../../../components'
import { connect } from 'react-redux'
import { getOrder, setOrderStatusSuccess } from './redux/actions'
import { setProductsFromOrder } from '../../../Cart/redux/actions'
import { orderSelector } from './redux/selectors'
import { useSetToast } from '../../../../hooks/useSetToast'
import { CustomerData, OrderedProducts, Track } from './components'
import { PayForm } from '../../components/PayForm'

import './OrderDetail.scss'

const OrderDetail = ({
	order: { data, isLoading, errorMsg },
	getOrder,
	setOrderStatusSuccess,
	setProductsFromOrder,
}) => {
	const isMounted = useRef(false)
	const { orderId } = useParams()
	const { setToast } = useSetToast()

	useEffect(() => {
		if (!isMounted.current) {
			isMounted.current = true
			getOrder(orderId, setToast)
		}
	}, [data, getOrder, orderId, setToast, isMounted])

	const total = useMemo(() => {
		if (data) {
			const { sale, products, deliveryPrice } = data

			if (products && products.length > 0) {
				let totalCount = 0
				let totalSummProd = 0
				let isPayDelivery = false
				let totalSumm = 0
				for (let item of products) {
					totalCount += item.count
					totalSummProd += item.count * item.price
				}
				const dopSale =
					!!sale && sale > 0 ? Math.round(totalSummProd * (sale / 100)) : null

				// if (totalSummProd >= 7000) {
				// 	totalSumm =
				// 		totalSummProd -
				// 		(dopSale ? dopSale : Math.round(totalSummProd * 0.1))
				// 	return { totalCount, totalSummProd, isPayDelivery, totalSumm }
				// }

				totalSumm = dopSale ? totalSummProd - dopSale : totalSummProd

				// if (totalSummProd >= 5000) {
				// 	return { totalCount, totalSummProd, isPayDelivery, totalSumm }
				// }
				isPayDelivery = true
				totalSumm =
					deliveryPrice && +deliveryPrice > 0
						? totalSumm + deliveryPrice
						: totalSumm
				return { totalCount, totalSummProd, isPayDelivery, totalSumm }
			}
		}
		return null
	}, [data])

	const getStatus = useCallback(() => {
		const { track, ready, paid, payType, revoke } = data

		if (revoke) {
			return <span className={'order-paid__not'}>отменён</span>
		}

		if (!!track) {
			return <span className={'order-ready'}>отправлен</span>
		}
		if (!ready) {
			return 'в обработке'
		}
		if (paid) {
			return (
				<span className={'order-paid__yes'}>оплачен, готовится к отправке</span>
			)
		}
		if (payType !== 'bankcard') {
			return <span className={'order-paid__yes'}>готовится к отправке</span>
		}

		return (
			<>
				{!!total ? (
					!total.isPayDelivery ? (
						<>
							<span className={'order-paid__not'}>ожидает оплаты</span>
							<br />
							<PayForm
								orderId={data._id}
								amount={total.totalSumm}
								orderNumber={data.orderNumber}
								email={data.userData.email}
								phone={data.userData.phone}
								setSuccessStatus={setOrderStatusSuccess}
							/>
						</>
					) : data.deliveryPrice && data.deliveryPrice > 0 ? (
						<>
							<span className={'order-paid__not'}>ожидает оплаты</span>
							<br />
							<PayForm
								orderId={data._id}
								amount={total.totalSumm}
								orderNumber={data.orderNumber}
								email={data.userData.email}
								phone={data.userData.phone}
								setSuccessStatus={setOrderStatusSuccess}
							/>
						</>
					) : (
						<span className={'order-paid__not'}>
							ожидает оплаты, оплата будет доступна поле определеня
							Администратором цены доставки
						</span>
					)
				) : (
					<span className={'order-paid__not'}>ожидает оплаты</span>
				)}
			</>
		)
	}, [data, total])

	const getTitle = useCallback(
		(isMainTitle = false) => {
			if (isLoading) {
				return 'Поиск заказа'
			}
			if (!data) {
				return 'Заказ не найден'
			}
			if (isMainTitle) {
				return `Заказ № ${data.orderNumber} от ${format(
					new Date(data.created),
					'dd.MM.yyyy'
				)}г.`
			}
			return `Заказ № ${data.orderNumber}`
		},
		[data, isLoading]
	)

	return (
		<div className={'order-detail'}>
			<BreadCrumbs
				routes={[{ path: `/orders`, title: 'Все заказы' }]}
				lastElementName={getTitle()}
			/>
			<h1 className={'page-title'}>{getTitle(true)}</h1>
			{isLoading ? (
				<Preloader title={'Загрузка данных'} />
			) : errorMsg ? (
				<Error title={errorMsg} />
			) : data ? (
				<>
					<CustomerData
						communication={data.communication}
						orderNumber={data.orderNumber}
						userData={data.userData}
						delivery={data.delivery}
						comments={data.comments}
						address={data.address}
						payType={data.payType}
						created={data.created}
						getStatus={getStatus}
					/>
					{data.track && (
						<Track
							trackData={data.trackData ? JSON.parse(data.trackData) : null}
							track={data.track}
							delivery={data.delivery}
						/>
					)}
					<OrderedProducts
						products={data.products}
						repeatOrder={setProductsFromOrder}
						deliveryPrice={data.deliveryPrice}
						total={total}
						sale={data.sale}
					/>
				</>
			) : (
				<Empty title={'Заказ не найден'} />
			)}
		</div>
	)
}

export default connect(state => ({ order: orderSelector(state) }), {
	getOrder,
	setOrderStatusSuccess,
	setProductsFromOrder,
})(OrderDetail)
