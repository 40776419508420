import {
	Home,
	News,
	Catalog,
	ProductsDetail,
	Hits,
	Sale,
	Search,
	Auth,
	Admin,
	User,
	Cart,
	Orders,
	VideosPage,
	About,
	Help,
	CheckPage,
	Politica,
	Contact,
} from './pages'
import { CheckPaySuccess } from './pages/CheckPaySuccess'

export const routes = [
	{
		key: 1,
		path: ['/', '/home'],
		component: Home,
		isExact: true,
		meta: {},
	},
	{
		key: 2,
		path: [
			'/sale/:collectionName',
			'/hits/:collectionName',
			'/search/:collectionName',
			'/recommendation/:collectionName',
			'/product/:section/:group/:collectionName',
			'/product/:section/:collectionName',
		],
		component: ProductsDetail,
		isExact: true,
		title: 'Товары',
	},
	{
		key: 3,
		path: '/hits',
		component: Hits,
		isExact: true,
		title: 'Хиты продаж',
	},
	{
		key: 4,
		path: '/sale',
		component: Sale,
		isExact: true,
		title: 'Скидки',
	},
	{
		key: 5,
		path: '/search',
		component: Search,
		isExact: true,
		title: 'Поиск',
	},
	{
		key: 6,
		path: '/cart',
		component: Cart,
		isExact: true,
		title: 'Корзина',
	},
	{
		key: 7,
		path: ['/news/:news', '/news'],
		component: News,
		isExact: true,
		title: 'Новости',
	},
	{
		key: 8,
		path: ['/catalog/:section/:group', '/catalog/:section'],
		component: Catalog,
		isExact: true,
		title: 'Каталог',
	},
	{
		key: 9,
		path: ['/login', '/registration', '/forgot-password'],
		component: Auth,
		isExact: true,
		title: 'Вход',
	},
	{
		key: 10,
		path: '/admin',
		component: Admin,
		isPrivate: true,
		owner: 'admin',
	},
	{
		key: 11,
		path: '/orders',
		component: Orders,
		owner: 'user',
		isPrivate: true,
		title: 'Заказы',
	},
	{
		key: 12,
		path: '/user',
		component: User,
		owner: 'user',
		isExact: true,
		isPrivate: true,
		title: 'Кабинет',
	},
	{
		key: 13,
		path: '/video',
		component: VideosPage,
		isExact: true,
		title: 'Видео',
	},
	{
		key: 14,
		path: '/about',
		component: About,
		isExact: true,
		title: 'О нас',
	},
	{
		key: 15,
		path: '/help',
		component: Help,
		title: 'Полезная информация',
	},
	{
		key: 16,
		path: ['/check', '/check/:email'],
		component: CheckPage,
		isExact: true,
		title: 'Подтверждение email',
	},
	{
		key: 17,
		path: '/politica',
		component: Politica,
		isExact: true,
		title: 'Политика кофиденциальности',
	},
	{
		key: 18,
		path: '/contact',
		component: Contact,
		isExact: true,
		title: 'Наши контакты',
	},
	{
		key: 19,
		path: '/check-pay/:orderId',
		component: CheckPaySuccess,
		isExact: true,
		title: 'Наши контакты',
	},
]
