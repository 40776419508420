import {
	getCatalogFileApi,
	getUsersFileApi,
} from '../../../../../utils/api/adminApi'
import { checkResponse } from '../../../../../utils/helpers/checkResponse'
import { logoutSimpleAction } from '../../../../Auth/redux/actions'
import { error } from './constants'

export const getUsersFileAction = (setToast = () => {}) => {
	return dispatch => {
		setToast({
			data: {
				message: 'Подождите, данные отправляются',
			},
			duration: 4000,
		})

		return getUsersFileApi()
			.then(res => {
				if (!checkResponse(res)) {
					if (res.auth === false) {
						dispatch(logoutSimpleAction())
					}
					return setToast({
						data: {
							type: 'error',
							title: 'Ошибка!',
							message: res.message || error.request,
						},
					})
				}
				setToast({
					data: {
						type: 'success',
						title: 'Успех!',
						message:
							res.message || 'Файл с пользователями отправлен Вам на почту',
					},
				})
			})
			.catch(() => {
				setToast({
					data: {
						type: 'error',
						title: 'Ошибка!',
						message: error.connect,
					},
				})
			})
	}
}

export const getCatalogFileAction = (setToast = () => {}) => {
	return dispatch => {
		setToast({
			data: {
				message: 'Подождите, данные отправляются',
			},
			duration: 4000,
		})

		return getCatalogFileApi()
			.then(res => {
				if (!checkResponse(res)) {
					if (res.auth === false) {
						dispatch(logoutSimpleAction())
					}
					return setToast({
						data: {
							type: 'error',
							title: 'Ошибка!',
							message: res.message || error.request,
						},
					})
				}
				setToast({
					data: {
						type: 'success',
						title: 'Успех!',
						message: res.message || 'Файл с каталогом отправлен Вам на почту',
					},
				})
			})
			.catch(() => {
				setToast({
					data: {
						type: 'error',
						title: 'Ошибка!',
						message: error.connect,
					},
				})
			})
	}
}
