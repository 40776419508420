import { Order, Delivery, ReturnProducts } from './pages'

export const routes = [
	{
		link: '/help/order',
		title: 'Как сделать заказ',
		component: Order,
	},
	{
		link: '/help/delivery',
		title: 'Условия доставки и оплаты',
		component: Delivery,
	},
	{
		link: '/help/return',
		title: 'Возврат товара',
		component: ReturnProducts,
	},
]
