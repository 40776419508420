import React, { useCallback } from 'react'
import { Order } from './Order'

export const OrdersList = ({
	orders,
	changeOrderStatus,
	getNewData,
	sendProductsToCart,
}) => {
	const getOrdersData = useCallback(() => {
		if (orders && orders.length > 0) {
			return orders.map(item => (
				<Order
					key={item._id}
					{...item}
					changeOrderStatus={changeOrderStatus}
					getNewData={getNewData}
					sendProductsToCart={sendProductsToCart}
				/>
			))
		}
		return null
	}, [orders, changeOrderStatus, getNewData, sendProductsToCart])
	return <div className={'orders-found'}>{getOrdersData()}</div>
}
