import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useSetToast } from '../../../../hooks'
import { getUsersFileAction, getCatalogFileAction } from './redux/actions'

import './Files.scss'

const Files = ({ getUsersFileAction, getCatalogFileAction }) => {
	const [isSend, setIsSend] = useState(false)
	const { setToast } = useSetToast()

	const getFile = async (fileType = 'user') => {
		setIsSend(true)
		if (fileType === 'user') {
			await getUsersFileAction(setToast)
		} else {
			await getCatalogFileAction(setToast)
		}
		setIsSend(false)
	}

	return (
		<section className={'files-page'}>
			<h1 className={'page-title'}>Сформировать и получить на почту файл</h1>
			<div className={'wrap-button'}>
				<button
					className={'button'}
					onClick={() => getFile('user')}
					disabled={isSend}
				>
					Пользователи
				</button>
				<button
					className={'button'}
					onClick={() => getFile('catalog')}
					disabled={isSend}
				>
					Каталог
				</button>
			</div>
		</section>
	)
}

export default connect(() => ({}), {
	getUsersFileAction,
	getCatalogFileAction,
})(Files)
