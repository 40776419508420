import React from 'react'
import './Delivery.scss'
import '../Order/Order.scss'

export const Delivery = () => {
	return (
		<section className={'terms-delivery'}>
			<h1 className={'page-title'}>Условия доставки и оплаты</h1>
			<div className={'info'}>
				<p className={'info__item'}>
					После оформления заказа, мы сначала собираем ваш заказ, убеждаемся,
					что все есть в наличии и только после этого выставляем счет на оплату!
					Статус вашего заказа вы можете наблюдать в Личном Кабинете. Как только
					заказ собран, вы сможете оплатить ваш заказ через личный кабинет. О
					том, что заказ собран, вы получите уведомление на Электронную почту.
				</p>
				<h2 className={'sub-title'}>Доступны следующие способы оплаты:</h2>
				<p className={'info__item'}>
					<span className={'bold'}>
						Оплата заказа производится на нашем сайте
					</span>
					, через платежный терминал Модуль Банка. Для оплаты заказа введите
					данные вашей банковской карты и следуйте инструкциям на экране.{' '}
					<span className={'bold'}>ВАЖНО!</span> Не стоит переживать за свои
					персональные данные! Для подтверждения оплаты вам поступит СМС на
					телефон, от вашего банка. Без этой СМС, ни кто ваши средства снять не
					сможет, без вашего ведома. Все операции с картами зашифрованы банком и
					информация о вашей карте в система не сохраняется!
				</p>
				<h2 className={'sub-title'}>Способы доставки:</h2>
				<p className={'info__item'}>
					Доставка возможна любым способом, который удобен заказчику. Это может
					быть <span className={'bold'}>Почта России</span>,{' '}
					<span className={'bold'}>Транспортная компания СДЭК</span>,{' '}
					<span className={'bold'}>Самовывоз</span>. Желаемый способ доставки
					указывается при оформлении заказа и обговаривается с каждым при
					телефонном разговоре. При любом способе доставки заказчику сообщается
					Трек номер и сайт, по которому он может отследить движение посылки
					онлайн.
				</p>
				<h2 className={'sub-title'}>Стоимость доставки:</h2>
				<p className={'info__item'}>
					Доставка Почтой России осуществляется только Первым классом, Авиа
					почтой. При предварительной онлайн оплате сумма доставки
					рассчитывается индивидуально и добавляется к сумме заказа. В таком
					случае при получении посылки никакой дополнительной оплаты не
					требуется. Посылка выдается лишь по паспорту.
				</p>
				<p className={'info__item'}>
					При отправке Почтой России наложенным платежом сумма доставки зависит
					от общей стоимости заказа и веса посылки. Когда посылка отправляется,
					мы оплачиваем стоимость доставки посылки до вас и включаем эту сумму в
					наложенный платеж. Почта берет процент за оказание услуг по денежному
					переводу от вас к нам, процент за оценочную стоимость посылки и её
					страховку. Со всеми сборами средняя цена доставки наложенным платежом
					составляет 500р.
				</p>
				<p className={'info__item'}>
					При отправке посылки транспортной компанией производится
					предварительная оплата полной стоимости заказа вместе с заранее
					рассчитанной стоимостью доставки. При получении посылки ничего платить
					не нужно! Цена напрямую зависит от города получателя.
				</p>
				<p className={'info__item'}>
					При заказе <span className={'bold'}>от 5 000р</span> - доставка
					бесплатная.
				</p>
				<h2 className={'sub-title'}>Дополнительные бонусы:</h2>
				<p className={'info__item'}>
					При заказе <span className={'bold'}>от 7000 рублей</span>{' '}
					предоставляется скидка <span className={'bold'}>10%</span> (данная
					скидка не распространяется на некоторые позиции товаров, чья сумма
					превышает 7000 рублей).
				</p>
				<h2 className={'sub-title'}>Оплата банковской картой:</h2>
				<p className={'info__item'}>
					Для выбора оплаты товара с помощью банковской карты на соответствующей
					странице необходимо нажать кнопку Оплата заказа банковской картой.
					Оплата происходит через ПАО СБЕРБАНК с использованием банковских карт
					следующих платёжных систем:
					<ul className={'order-stap'}>
						<li className={'order-stap__item'}>МИР</li>
						<li className={'order-stap__item'}>VISA International</li>
						<li className={'order-stap__item'}>Mastercard Worldwide</li>
						<li className={'order-stap__item'}>JCB</li>
					</ul>
				</p>
				<p className={'info__item'}>
					Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на
					платёжный шлюз ПАО СБЕРБАНК. Соединение с платёжным шлюзом и передача
					информации осуществляется в защищённом режиме с использованием
					протокола шифрования SSL. В случае если Ваш банк поддерживает
					технологию безопасного проведения интернет-платежей Verified By Visa,
					MasterCard SecureCode, MIR Accept, J-Secure, для проведения платежа
					также может потребоваться ввод специального пароля.
				</p>
				<p className={'info__item'}>
					Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность
					сообщаемой персональной информации обеспечивается ПАО СБЕРБАНК.
					Введённая информация не будет предоставлена третьим лицам за
					исключением случаев, предусмотренных законодательством РФ. Проведение
					платежей по банковским картам осуществляется в строгом соответствии с
					требованиями платёжных систем МИР, Visa Int., MasterCard Europe Sprl,
					JCB.
				</p>
			</div>
		</section>
	)
}
