import React, { useState, useEffect } from 'react'
import { useCallback } from 'react'
import { useParams } from 'react-router'
import { BreadCrumbs } from '../../../../components/BreadCrumbs'
import { Error } from '../../../../components/Error'
import { Preloader } from '../../../../components/Preloader'
import { useSetToast } from '../../../../hooks/useSetToast'
import './CheckEmail.scss'

export const CheckEmail = ({ checkEmailAction, history }) => {
	const [isLoading, setIsLoading] = useState(true)
	const { email } = useParams()
	const { setToast } = useSetToast()

	const andAction = useCallback(
		(ok = true) => {
			if (ok) {
				history.push('/user')
				return
			}
			setIsLoading(false)
		},
		[history]
	)

	useEffect(() => {
		if (email) {
			checkEmailAction({ email }, setToast, andAction)
		}
	}, [andAction, email, setToast, checkEmailAction])

	return (
		<div className={'check_email'}>
			<BreadCrumbs lastElementName={'Проверка E-mail на достоверность'} />
			{isLoading ? (
				<Preloader title={'Идёт сравнение...'} />
			) : (
				<Error title={'E-mail не совпадает с зарегистрированным!'} />
			)}
		</div>
	)
}
