import { checkEmailApi } from '../../../../../utils/api/authApi'
import { checkResponse } from '../../../../../utils/helpers/checkResponse'
import {
	emailIsCheckedAction,
	logoutSimpleAction,
} from '../../../../Auth/redux/actions'

export const checkEmailAction = (data, setToast, andAction) => dispatch => {
	setToast({
		data: {
			message: 'Подождите, мы сравниваем E-mail c зарегистрированным',
		},
		duration: 1000,
	})
	return checkEmailApi(data)
		.then(res => {
			if (!checkResponse(res)) {
				if (res.auth === false) {
					dispatch(logoutSimpleAction())
					andAction(true)
					return setToast({
						data: {
							type: 'error',
							title: 'Ошибка!',
							message:
								'Сначала войдите в кабинет, затем пройдите по ссылке из письма полученого Вами при регистрации!',
						},
					})
				}
				andAction(false)
				return setToast({
					data: {
						type: 'error',
						title: 'Ошибка!',
						message: res.message || 'E-mail не совпадает с имеющимся!',
					},
				})
			}
			dispatch(emailIsCheckedAction())
			setToast({
				data: {
					type: 'success',
					title: 'Отлично!',
					message: res.message,
				},
			})
			andAction(true)
		})
		.catch(() => {
			setToast({
				data: {
					type: 'error',
					title: 'Ошибка!',
					message: 'Не удалось отправить данные на сервер',
				},
			})
			andAction(false)
		})
}
