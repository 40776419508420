const DEV = false;

export const API_ROOT = DEV
	? "http://localhost:3000"
	: "https://sibirskylov.ru";

export const DATA_FOR_SIGNATURE = {
	merchantId: "67fb5acb-a287-4391-9d8b-c41418631353",
	secretKey: "B2563722498194DF567345714853603E",
	secretKeyDev: "9D5147D7492183B1C002AE657F9D0AA0",
	receiptContact: "89045764700@mail.ru",
	callbackUrl: `${API_ROOT}/api/order/success`,
	testing: 0,
	successUrl: `https://pay.modulbank.ru/success`,
	salt: "dPUTLtbMfcTGzkaBnGtseKlcQymCLrYI",
};
