import React, { useState, useCallback } from 'react'
import { reduxForm, Field } from 'redux-form'
import { FormItem } from '../../../components'
import { validateCollectionSalePrice as validate } from '../../../../../utils/validators'
import { ChoiceCollection } from './ChoiceCollection'
import { useSetToast } from '../../../../../hooks'

const EditCollectionSalePriceFormRoot = ({
	rawData,
	groups,
	setSalePrice,
	submitting,
	handleSubmit,
	change,
	untouch,
	valid,
	reset,
}) => {
	const [collectionData, setCollectionData] = useState(null)
	const [isIsset, setIsIsset] = useState(false)

	const { setToast } = useSetToast()

	const changeParentCollection = useCallback(
		data => {
			change('salePrice', 0)
			untouch('salePrice')
			setCollectionData(() => data)
		},
		[change, untouch]
	)

	const resetChange = useCallback(() => {
		reset()
		setCollectionData(() => null)
	}, [])

	const resetAll = useCallback(() => {
		resetChange()
		setIsIsset(true)
	}, [resetChange])

	const onSubmit = useCallback(
		values => {
			const { collectionId, salePrice } = values
			setSalePrice({ collectionId, salePrice }, setToast, resetAll)
		},
		[setSalePrice, resetAll]
	)

	return (
		<form className={'form'} onSubmit={handleSubmit(onSubmit)}>
			<ChoiceCollection
				groups={groups}
				rawData={rawData}
				getCollectionData={changeParentCollection}
				reset={resetChange}
				isIsset={isIsset}
				setIsIsset={setIsIsset}
			/>
			{collectionData && (
				<>
					<Field
						component={FormItem}
						type={'number'}
						name={'salePrice'}
						label={'Укажите цену товаров со скидкой или 0'}
					/>
					<button
						type={'submit'}
						className={'button'}
						disabled={submitting || !valid}
					>
						Применить скидку к товарам
					</button>
				</>
			)}
		</form>
	)
}

const EditCollectionSalePriceForm = reduxForm({
	form: 'editCollectionSalePrice',
	initialValues: {
		salePrice: 0,
	},
	validate,
})(EditCollectionSalePriceFormRoot)

export const EditCollectionSalePrice = ({ rawData, setSalePrice, groups }) => (
	<>
		<h2 className="form-title">
			Изменение скидки для всех товаров в коллекции
		</h2>
		<EditCollectionSalePriceForm
			groups={groups}
			rawData={rawData}
			setSalePrice={setSalePrice}
		/>
	</>
)
