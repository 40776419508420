import React from 'react'
import './ReturnProducts.scss'

export const ReturnProducts = () => {
	return (
		<section className={'return-products'}>
			<h1 className={'page-title'}>Как вернуть товар</h1>
			<div className={'info'}>
				<p className={'info__item'}>
					Согласно Федеральному Закону РФ "О защите прав потребителей" (№2300-1
					от 07.02.1992г) потребитель вправе обменять непродовольственный товар
					надлежащего качества на аналогичный товар у продавца, у которого этот
					товар был приобретен, если указанный товар не подошел по форме,
					габаритам, фасону, расцветке, размеру или комплектации. Потребитель
					имеет право на обмен непродовольственного товара надлежащего качества
					в течение четырнадцати дней, не считая дня его покупки.
				</p>
				<p className={'info__item'}>
					В случае каких либо ошибок вы всегда можете связаться с нами по
					указанным контактам.{' '}
					<span className={'bold'}>
						Любой вопрос можно решить. Мы всегда пойдем вам на встречу!
					</span>
				</p>
			</div>
		</section>
	)
}
