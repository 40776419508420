import React, { useMemo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useSetToast } from '../../../../../../hooks/useSetToast'

import './OrderedProducts.scss'

export const OrderedProducts = ({
	products,
	repeatOrder,
	deliveryPrice,
	total,
	sale,
}) => {
	const location = useLocation()
	const { setToast } = useSetToast()
	const productsElements = useMemo(
		() =>
			products.map((item, index) => (
				<tr key={item._id} className={'ordered-products__item'}>
					<td>{index + 1}</td>
					<td className={'name'}>
						<NavLink
							to={{
								pathname: item.link,
								state: { prevPath: location.pathname },
							}}
						>
							{item.title}
						</NavLink>
					</td>
					<td>{item.price}</td>
					<td>{item.count}</td>
					<td>{item.price * item.count}</td>
				</tr>
			)),
		[products, location]
	)

	return (
		<section className={'ordered-products__section'}>
			<h2 className={'order-item__title'}>Заказанные товары</h2>
			<table className={'ordered-products'}>
				<thead>
					<tr className={'ordered-products__title'}>
						<th>№</th>
						<th className={'name'}>Название</th>
						<th>Цена</th>
						<th>Кол.</th>
						<th>Сумма</th>
					</tr>
				</thead>
				<tbody>{productsElements}</tbody>
				<tfoot>
					<tr>
						<td></td>
						<td></td>
						<td>Подытог:</td>
						<td>{total.totalCount}</td>
						<td>{total.totalSummProd}</td>
					</tr>

					<tr>
						<td></td>
						<td></td>
						<td>Достака:</td>
						<td></td>
						<td>
							{!total.isPayDelivery
								? 'Бесплатно'
								: deliveryPrice && deliveryPrice > 0
								? deliveryPrice
								: 'Рассчитывется'}
						</td>
					</tr>
					 {/* || total.totalSummProd >= 7000 */}
					{(!!sale && sale > 0) ? (
						<tr className={'ordered-products__foot'}>
							<td></td>
							<td></td>
							<td>Скидка:</td>
							<td></td>
							<td> -{`${sale}%`}
								{/* -{Math.round(total.totalSummProd * (sale ? sale / 100 : 0.1))} (
								{sale ? `${sale}%` : '10%'}) */}
							</td>
						</tr>
					) : null}
					<tr className={'ordered-products__foot'}>
						<td></td>
						<td></td>
						<td>Итого:</td>
						<td></td>
						<td>{total.totalSumm}</td>
					</tr>
				</tfoot>
			</table>
			<div className={'wrap-button'}>
				<button
					className={'button'}
					onClick={() => repeatOrder(products, setToast)}
				>
					Повторить заказ
				</button>
			</div>
		</section>
	)
}
